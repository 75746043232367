<template>
  <div class="info">
    <top-bar :title="!submitData.id?'微型消防站信息新增':'微型消防站信息修改'" :left="true"></top-bar>
    <van-popup v-model="memberPostShow" position="bottom" >
      <van-cascader title="请选择" :options="selectData.postList"
                    @close="memberPostShow = false" @finish="memberPostFinish" :field-names="fieldNames"/>
    </van-popup>
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">微型消防站信息</span>
        </template>
      </van-cell>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.position" label="所在位置"  placeholder="请输入" input-align="right" /></van-cell>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.fireEngines" label="消防车数量"  placeholder="请输入" input-align="right" /></van-cell>
    </van-cell-group>
    <van-cell-group v-if="checked" class="info-second">
      <van-cell title="消防站成员" class="info-title" center>
        <van-switch v-model="memberSubmit" size="24px" @change="memberSubmitShow"/>
      </van-cell>
    </van-cell-group>
    <template v-if="memberSubmit">
      <van-cell-group v-for="(item, index) in submitData.memberList" :key="index" class="info-second">
        <van-cell :title="'成员'+(index+1)" class="info-title">
          <van-image :src="require('@/assets/img/problem_add.png')" height="25" width="25" style="margin-right: 10px" @click="addMember"/>
          <van-image :src="require('@/assets/img/problem_delete.png')" height="25" width="25" style="margin-right: 10px" @click="deleteMember(index)"/>
          <van-image :src="require(`@/assets/img/problem_${memberShow?'shrink':'open'}.png`)" height="25" width="25" @click="shrink(index)"/>
        </van-cell>
        <div v-show="item.opened">
          <van-cell title="成员姓名">
            <van-field clearable clear-trigger="always" v-model="item.name" placeholder="请输入"/>
          </van-cell>
          <van-cell title="成员职务" :value="item.post==1?'队员':item.post==2?'队长':'请选择'" @click="checkMemberPost(index)"
                    :value-class="{'value-common':item.post=='请选择'}" :is-link="checked" />
          <van-cell title="联系电话">
            <van-field clearable clear-trigger="always" v-model="item.mobile" placeholder="请输入"/>
          </van-cell>
        </div>
      </van-cell-group>
    </template>
    <van-row class="btns">
      <van-col :span="24">
        <van-button type="info" size="large" round @click="submit">完成</van-button>
      </van-col>
    </van-row>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'


import {getbelongSubArea, getDictTree,getVirtualDict} from '@/utils/common'

import { mapState } from 'vuex'
export default {
  data () {
    return {
      detailData: {},
      checked: true,
      memberSubmit: false,
      memberShow: true,
      postShow: false,
      memberPostShow: false,
      fieldNames: {
        text: 'label',
        value: 'value',
        postList: [{value:1,label:'队员'},{value:2,label:'队长'}]
      },
      submitData: {
        id: '',
        position: '',
        fireEngines: '',
        memberList: [],
        orgId:0
      },
      selectData: {
        postList: [{value:1,label:'队员'},{value:2,label:'队长'}]
      },
      selectMsg: {
        post: '请选择',
      },
    }
  },
  computed:{...mapState(['ProblemId'])},
  components :{
    topBar
  },
  methods: {
    shrink (index) {
      this.$set(this.submitData.memberList[index], 'opened', !this.submitData.memberList[index]['opened'])
    },
    init () {
      if (this.submitData.id) {
        this.memberSubmit = true
        this.getInfo()
      }else {
        this.memberSubmit = false
      }
    },
    // 获取详情
    getInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/minifirestation/info/'+this.submitData.id),
        method: 'post',
        params: this.$http.adornParams({
        })
      }).then(({data})=> {
        if (data && data.code === 0) {
          this.submitData.position = data.appMiniFireStation.position
          this.submitData.fireEngines = data.appMiniFireStation.fireEngines
          this.submitData.memberList = data.appMiniFireStation.memberList.map((item,index) => {
            return {
              ...data.appMiniFireStation.memberList[index],
              opened: true
            }
          }) || [{
            name: '',
            post: '请选择',
            mobile: '',
            opened: true,
            statusType: 0
          }]
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    submit () {
      if ( this.submitData.position === '') {return this.$toast.fail('失败:请先输入所在位置');}
      if ( this.submitData.fireEngines === '') {return this.$toast.fail('失败:请先输入消防车数量');}
      // 成员校验
      if (this.submitData.memberList&&this.submitData.memberList.length != 0) {
        for (var i = 0; i < this.submitData.memberList.length; i++) {
          if (!this.submitData.memberList[i].name) {
            return this.$toast.fail('失败:请输入成员姓名')
          }
          if (!this.submitData.memberList[i].post) {
            return this.$toast.fail('失败:请选择成员职务')
          }
          if (!this.submitData.memberList[i].mobile) {
            return this.$toast.fail('失败:请输入联系电话')
          }
        }
      }
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/minifirestation/save`),
        method: 'post',
        data : this.$http.adornParams({
          id: this.submitData.id,
          community: this.submitData.orgId,
          position: this.submitData.position,
          fireEngines: this.submitData.fireEngines,
          memberList: this.submitData.memberList
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    memberSubmitShow() {
      if (this.memberSubmit && this.submitData.memberList && this.submitData.memberList.length === 0) {
        this.addMember()
      }
    },
    addMember() {
      var member = {
        name: '',
        post: '请选择',
        mobile: '',
        opened: true,
        statusType: this.submitData.id ? 1 : 0
      }
      this.submitData.memberList.push(member)
    },
    deleteMember(i) {
      this.submitData.memberList.splice(i, 1)
      if (this.submitData.memberList.length === 0) {
        this.memberSubmit = false
      }
    },
    checkMemberPost(index) {
      this.memberPostShow = true
      this.i = index
    },
    memberPostFinish(value) {
      if (value) {
        this.submitData.memberList[this.i].post = value.selectedOptions[value.selectedOptions.length - 1].label
        this.submitData.memberList[this.i].post = value.value
      }
      this.memberPostShow = false
    },
    onCancel() {
    },
  },
  created () {
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style scoped>
.left >>> .van-field__control {
  text-align: left !important;
}
</style>
